import React, { useState, useEffect, forwardRef } from "react"

// Importing @nprapps/sidechain above creates a custom element, which requires
// us to define a type for it so typescript doesn't complain.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "side-chain": {
        src: string
      }
    }
  }
}

export interface IframeProps {
  src: string
  height?: number
}

type IsLoadedCallback = (b: boolean) => void

const lazyloadSidechain = async (isLoadedCallback: IsLoadedCallback) => {
  await import("@nprapps/sidechain")
  isLoadedCallback(true)
}

export const Iframe = ({ src, height }: IframeProps) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    lazyloadSidechain(setLoaded)
  }, [loaded])

  // Render an empty placeholder with an appropriate height, if saved
  if (loaded) {
    return <side-chain src={src}></side-chain>
  } else {
    return <div style={{ minHeight: height || 0 }}></div>
  }
}
